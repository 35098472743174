<template>
  <div class="blog-columns">
    <BlogItem />
    <BlogItem />
    <BlogItem />
    <BlogItem />
    <BlogItem />
  </div>
</template>

<script>
import BlogItem from "./BlogItem.vue";
export default {
  components: {
    BlogItem,
  },
};
</script>

<style scoped>
.blog-columns {
  margin-top: 60px;
  margin-bottom: 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

@media screen and (max-width: 1200px) {
  .blog-columns {
    margin-top: 40px;
    gap: 40px 25px;
  }
}

@media screen and (max-width: 850px) {
  .blog-columns {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 650px) {
  .blog-columns {
    gap: 40px 15px;
  }
}

@media screen and (max-width: 570px) {
  .blog-columns {
    grid-template-columns: 1fr;
    gap: 40px 15px;
  }
}
</style>
