<template>
  <div>
    <div class="blog-columns-item">
      <div class="blog-columns-item-image">
        <a href="javascript:;"><img src="@/assets/img/blog-1.png" alt="" /></a>
      </div>
      <div class="blog-columns-item-container">
        <h5><a href="javascript:;">What is real estate tokenization</a></h5>
        <p>
          We create asset-backed security tokens to help security issuers raise
          more capital and offer investors true
        </p>
        <div class="blog-columns-item-container-author">
          <a href="javascript:;">
            <img src="@/assets/img/avatar/avatar-2.png" alt="" />
          </a>
          <div class="blog-columns-item-container-author-content">
            <h6 class="author-name"><a href="javascript:;">Jessica owen</a></h6>
            <span class="blog-date">january 16 2022 4min read</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.blog-columns-item {
  background: #ffffff;
  box-shadow: 0px 3.90076px 28.2805px 4.87595px rgba(111, 111, 111, 0.25);
  border-radius: 29.2557px;
}

.blog-columns-item .blog-columns-item-image img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 29.2557px;
}

.blog-columns-item-container {
  padding: 30px 40px;
}

.blog-columns-item-container h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 13px;
}

.blog-columns-item-container p {
  font-style: normal;
  font-weight: normal;
  font-size: 13.6527px;
  line-height: 140.62%;
  color: #363636;
  margin-bottom: 23px;
}

.blog-columns-item-container-author {
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center !important;
  column-gap: 15px;
  height: 100%;
}

.blog-columns-item-container-author img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.blog-columns-item-container-author
  .blog-columns-item-container-author-content {
  color: #000000;
  margin-top: 10px;
}

.blog-columns-item a {
  color: #000000;
}

.blog-columns-item-container-author
  .blog-columns-item-container-author-content
  h6 {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 4px;
}

.blog-columns-item-container-author
  .blog-columns-item-container-author-content
  span {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

@media screen and (max-width: 1300px) {
  .blog-columns-item .blog-columns-item-image img {
    height: 200px;
  }
}

@media screen and (max-width: 1200px) {
  .blog-columns-item-container {
    padding: 25px 30px;
  }

  .blog-columns-item-container-author img {
    width: 30px;
    height: 30px;
  }

  .blog-columns-item-container-author
    .blog-columns-item-container-author-content
    h6 {
    font-size: 13px;
  }
}

@media screen and (max-width: 950px) {
  .blog-columns-item .blog-columns-item-image img {
    height: 170px;
  }

  .blog-columns-item-container {
    padding: 25px 20px;
  }

  .blog-columns-item-container h5 {
    font-size: 19px;
  }

  .blog-columns-item-container p {
    font-size: 12px;
  }
}

@media screen and (max-width: 850px) {
  .blog-columns-item .blog-columns-item-image img {
    height: 200px;
  }
}

@media screen and (max-width: 650px) {
  .blog-columns-item-container h5 {
    font-size: 17px;
  }
}

@media screen and (max-width: 570px) {
  .blog-columns-item-container h5 {
    font-size: 19px;
  }
  .blog-columns-item .blog-columns-item-image img {
    height: 250px;
  }
}

@media screen and (max-width: 480px) {
  .blog-columns-item .blog-columns-item-image img {
    height: 200px;
  }
}
</style>
