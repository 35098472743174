<template>
  <div class="blog-container">
    <h4>The Blog</h4>
    <div class="relsify-nav-tab">
      <a href="javascript" class="active">Latest Posts </a>
      <a href="javascript">Realestate </a>
      <a href="javascript">Block Chain </a>
      <a href="javascript">Tokenization </a>
      <a href="javascript">Smart contract </a>
    </div>

    <BlogColumns />
    <br />
    <Blog2Column1Large />

    <BlogColumns />
  </div>
</template>

<script>
import BlogColumns from "./Blog3Columns.vue";
import Blog2Column1Large from "./Blog2Column1Large.vue";
export default {
  components: {
    BlogColumns,
    Blog2Column1Large,
  },
};
</script>

<style scoped>
.blog-container {
  background: #ffffff;
  width: 100%;
  padding: 100px 120px 100px;
}

.blog-container h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 37.41px;
  line-height: 56px;
  text-align: center;
  color: #000000;
}

@media screen and (max-width: 1300px) {
  .blog-container {
    padding: 100px 80px 100px;
  }
}

@media screen and (max-width: 1250px) {
  .blog-container {
    padding: 100px 50px 100px;
  }
}

@media screen and (max-width: 1150px) {
  .blog-container {
    padding: 80px 40px;
  }
}

@media screen and (max-width: 950px) {
  .blog-container {
    padding: 80px 20px;
  }
}
</style>
