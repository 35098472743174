<template>
  <div>
    <div class="blog-columns">
      <BlogItem2 />
      <BlogItem />
    </div>
  </div>
</template>

<script>
import BlogItem from "./BlogItem.vue";
import BlogItem2 from "./BlogItem2.vue";
export default {
  components: {
    BlogItem,
    BlogItem2,
  },
};
</script>

<style scoped>
.blog-columns {
  margin-top: 60px;
  margin-bottom: 60px;
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 40px 15%;
}

@media screen and (max-width: 1400px) {
  .blog-columns {
    margin-top: 60px;
    margin-bottom: 60px;
    grid-template-columns: 1fr 400px;
    gap: 40px 10%;
  }
}

@media screen and (max-width: 1200px) {
  .blog-columns {
    margin-top: 40px;
    margin-bottom: 40px;
    grid-template-columns: 1fr 400px;
    gap: 40px 8%;
  }
}

@media screen and (max-width: 980px) {
  .blog-columns {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 5%;
  }
}

@media screen and (max-width: 850px) {
  .blog-columns {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 4%;
  }
}

@media screen and (max-width: 650px) {
  .blog-columns {
    gap: 40px 15px;
  }
}

@media screen and (max-width: 610px) {
  .blog-columns {
    grid-template-columns: 1fr;
    gap: 40px 15px;
  }
}

@media screen and (max-width: 570px) {
  .blog-columns {
    grid-template-columns: 1fr;
    gap: 40px 15px;
  }
}
</style>
