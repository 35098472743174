<template>
  <div class="blog-columns-item">
    <div class="blog-columns-item-image">
      <a href="javascript:;"><img src="@/assets/img/blog-4.png" alt="" /></a>
    </div>
    <div class="blog-columns-item-container">
      <h5><a href="javascript:;">What is real estate tokenization</a></h5>
      <p>
        We create asset-backed security tokens to help security issuers raise
        more capital and offer investors true
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.blog-columns-item {
  background: #ffffff;
  border-radius: 29.2557px;
}

.blog-columns-item .blog-columns-item-image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 29.2557px;
}

.blog-columns-item-container {
  padding: 30px 0px;
}

.blog-columns-item-container h5 {
  font-weight: 600;
  font-size: 40px;
  line-height: 66px;
  color: #000000;
  margin-bottom: 13px;
}

.blog-columns-item-container p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140.62%;
  color: #363636;
  margin-bottom: 23px;
}

.blog-columns-item a {
  color: #000000;
}

@media screen and (max-width: 1400px) {
  .blog-columns-item-container h5 {
    font-size: 35px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1300px) {
  .blog-columns-item .blog-columns-item-image img {
    height: 350px;
  }

  .blog-columns-item-container p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .blog-columns-item-container {
    padding: 25px 10px;
  }

  .blog-columns-item-container h5 {
    font-size: 30px;
  }
}

@media screen and (max-width: 1050px) {
  .blog-columns-item .blog-columns-item-image img {
    height: 270px;
  }

  .blog-columns-item-container {
    padding: 15px 10px;
  }

  .blog-columns-item-container h5 {
    font-size: 25px;
    line-height: 36px;
  }
}

@media screen and (max-width: 980px) {
  .blog-columns-item {
    background: #ffffff;
    box-shadow: 0px 3.90076px 28.2805px 4.87595px rgba(111, 111, 111, 0.25);
  }
  .blog-columns-item .blog-columns-item-image img {
    height: 200px;
  }

  .blog-columns-item-container {
    padding: 25px 25px;
  }

  .blog-columns-item-container h5 {
    font-size: 22px;
  }

  .blog-columns-item-container p {
    font-size: 15px;
  }
}

@media screen and (max-width: 850px) {
  .blog-columns-item .blog-columns-item-image img {
    height: 200px;
  }

  .blog-columns-item-container {
    padding: 20px 20px;
  }

  .blog-columns-item-container h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 650px) {
  .blog-columns-item-container h5 {
    font-size: 17px;
  }
}

@media screen and (max-width: 610px) {
  .blog-columns-item-container h5 {
    font-size: 19px;
  }
  .blog-columns-item .blog-columns-item-image img {
    height: 250px;
  }
}

@media screen and (max-width: 480px) {
  .blog-columns-item .blog-columns-item-image img {
    height: 200px;
  }
}
</style>
