<template>
  <div>
    <BlogBanner />
    <BlogContainer />
  </div>
</template>

<script>
import BlogBanner from "../components/extras/banners/BlogBanner.vue";
import BlogContainer from "../components/extras/blog/BlogContainer.vue";
import { mapActions } from "vuex";
export default {
  components: {
    BlogBanner,
    BlogContainer,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
